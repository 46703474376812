<script lang="ts" setup>
type PopUpAdData = {
	popup_id: number;
	popup_name: string;
	popup_delay_time: number;
	popup_company_priority: number;
	popup_priority: number;
	popup_data: string;
	log_id: number;
};

type AdAction = {
	logId: number;
	actionId: string;
};

const props = defineProps<{ openGame: (game: string) => void }>();

const emit = defineEmits<{
	(event: "sendAdAction", adAction: AdAction): void;
	(event: "openModal", modal: string): void;
}>();

const router = useRouter();

const modalOpen = ref(false);
const modalHTML = ref("");
const dynamicAdModal = ref<HTMLDivElement>();
const currentModal = ref<null | number>(null);
const logId = ref<null | number>(null);
const popUpAds = useState<null | PopUpAdData[]>("popUpAds");
const regexGame = /(?:\?|&)game=([^&]+)/;
const SCRIPT_ID = "dynamicAdModalScript";

const closeModal = () => {
	if (popUpAds.value) {
		popUpAds.value = popUpAds.value?.filter((modal) => modal.popup_id !== currentModal.value);
	}

	dynamicAdModal?.value?.classList?.remove("active");

	modalOpen.value = false;
	modalHTML.value = "";

	const isDynamicScript = document.getElementById(SCRIPT_ID);
	if (isDynamicScript) {
		isDynamicScript.remove();
	}
};

const handleClick = (e: MouseEvent) => {
	const targetElement = e?.target as HTMLButtonElement;
	const button = targetElement?.closest("button");
	const modalHasActiveClass = dynamicAdModal?.value?.classList?.contains("active");

	if (!button) {
		return;
	}

	const buttonName = button?.name;
	const btnActionValue = button?.attributes?.getNamedItem("aria-action-value")?.value;
	const btnActionName = button?.attributes?.getNamedItem("aria-action-name")?.value;

	if (logId.value && btnActionName) {
		if (buttonName === "showOffers" && modalHasActiveClass) {
			return;
		}
		emit("sendAdAction", { logId: logId.value, actionId: btnActionName });
	}

	if (buttonName === "openInternalPage" && btnActionValue) {
		router?.push(btnActionValue.startsWith("/") ? btnActionValue : `/${btnActionValue}`);
		if (btnActionValue?.includes("game=")) {
			const match = btnActionValue.match(regexGame);
			const gameValue = match?.[1];

			if (gameValue && props.openGame) {
				props.openGame(gameValue);
			}
		}
	}

	if (buttonName === "openCash" && btnActionValue) {
		window?.$cash?.$router?.push?.(btnActionValue);
	}

	if (buttonName === "openInternalModal" && btnActionValue) {
		emit("openModal", btnActionValue);
	}

	if (buttonName === "showOffers" && btnActionValue) {
		if (modalHasActiveClass) {
			return;
		}

		const selectedOffer = Number(btnActionValue);
		const activeButton = document.querySelector(`[aria-action-name="${btnActionName}"]`);
		const bestOfferElement = document.getElementById("best-offer");
		const hiddenOffers = [...document.querySelectorAll(".common-offer")];

		if (!activeButton || !bestOfferElement) {
			return;
		}

		activeButton?.classList?.add("active");
		dynamicAdModal.value?.classList?.add("active");

		const offersLength = hiddenOffers?.length + 1;

		for (let i = 0; i < offersLength; i++) {
			const isBestOffer = i + 1 === selectedOffer;

			const offerElement = isBestOffer ? bestOfferElement : hiddenOffers[0];

			const destinationOfferPlace = document.getElementById(`offer-place-${i + 1}`);

			if (!destinationOfferPlace || !offerElement) {
				return;
			}

			destinationOfferPlace?.appendChild(offerElement);

			if (hiddenOffers?.length > 0 && !isBestOffer) {
				hiddenOffers.shift();
			}
		}
		return;
	}

	if (buttonName) {
		closeModal();
	}
};

const handleOpenDynamicModal = (modal: PopUpAdData) => {
	setTimeout(
		() => (modalHTML.value = modal?.popup_data || ""),
		modal.popup_delay_time ? modal.popup_delay_time * 1000 : 0
	);
};

const initializationModal = () => {
	if (popUpAds.value) {
		const modal = popUpAds.value[0];

		if (modal) {
			currentModal.value = modal?.popup_id;
			logId.value = modal?.log_id;
			handleOpenDynamicModal(modal);
		}
	}
};

const openModal = () => {
	modalOpen.value = true;

	initializationModal();
	setTimeout(() => dynamicAdModal.value?.addEventListener("click", handleClick), 500);
};

watch(
	() => popUpAds.value,
	() => {
		openModal();
	}
);

const executeScripts = () => {
	nextTick(() => {
		const script = document.querySelector<HTMLScriptElement>(".dynamicAdModal script");
		if (!script) {
			return;
		}

		const newScript = document.createElement("script");
		newScript.id = SCRIPT_ID;

		if (script?.src) {
			newScript.src = script?.src;
			newScript.async = false;
			document.body.appendChild(newScript);
		} else {
			newScript.textContent = script.textContent;
			document.body.appendChild(newScript);
		}
		script.remove();
	});
};

watch(
	() => modalHTML.value,
	() => {
		executeScripts();
	}
);

onUnmounted(() => {
	dynamicAdModal.value?.removeEventListener("click", handleClick);
});
</script>
<template>
	<Teleport to="body">
		<div v-if="modalOpen" ref="dynamicAdModal" class="dynamicAdModal" @click.stop v-html="modalHTML" />
	</Teleport>
</template>
